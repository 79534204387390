
import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";


function Redirect() {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    useEffect(() => {
        const startParam = tg.initDataUnsafe.start_param
        navigate(`/${startParam.replace('_','/')}`);
    },[])

    return (
        <></>

    );
}

export default Redirect;
